import "./bootstrap";
import "flowbite";
import hljs from "highlight.js";
import "highlight.js/styles/base16/macintosh.css";
import {fabric} from "fabric";
import Sortable from "sortablejs";
import "./abilities-list";
import Fuse from "fuse.js";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import './reverb/device-inactivity.js'
import {hideGlobalLoadingModal, showGlobalLoadingModal} from "./loading-modal.js";
import Alpine from "alpinejs";
import QRCode from "qrcode";
import './toast-notify.js';

const fpPromise = FingerprintJS.load();

window.Alpine = Alpine;
window.hljs = hljs;
window.fabric = fabric;
window.Sortable = Sortable;
window.Fuse = Fuse;
window.FingerprintJS = FingerprintJS;
window.QRCode = QRCode;
window.showLoading = showGlobalLoadingModal;
window.hideLoading = hideGlobalLoadingModal;

Alpine.start();

const fp = await fpPromise;
const result = await fp.get();

// Obtém o identificador único do dispositivo
const visitorId = result.visitorId;
console.log(`app.js :: Visitor ID: ${visitorId}`);
const logoutEl = document.getElementById("logout-device_fingerprint_id")
if (logoutEl) {
    logoutEl.value = visitorId;
}

document.querySelectorAll(".copyButton").forEach((button) => {
    button.addEventListener("click", function () {
        const text = this.getAttribute("data-clipboard-text");
        const textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        try {
            const successful = document.execCommand("copy");
            const msg = successful ? "sucesso" : "falha";
            console.log("Fallback: Texto copiado com " + msg);
            // Opcional: Exibir notificação de sucesso/falha
            alert("Texto copiado!");
        } catch (err) {
            console.error("Fallback: Erro ao copiar texto", err);
        }
        document.body.removeChild(textarea);
    });
});

document.addEventListener("DOMContentLoaded", () => {
    // Hightlight.js
    document.querySelectorAll(".language-json").forEach((element) => {
        hljs.highlightElement(element);
    });
});

document.addEventListener("DOMContentLoaded", () => {
    // Checa se `EditorCompose` está definido antes de instanciá-lo
    if (typeof EditorCompose !== 'undefined') {
        const myEditor = new EditorCompose();
        window.myEditor = myEditor;
    }
});

window.findCircular = (obj, path = [], seen = new Map()) => {
    if (typeof obj !== "object" || obj === null) {
        return null;
    }

    if (seen.has(obj)) {
        return {
            path: seen.get(obj),
            key: path[path.length - 1],
            circularReference: path.join(" -> "),
        };
    }

    seen.set(obj, [...path]);

    for (const [key, value] of Object.entries(obj)) {
        const newPath = [...path, key];
        const result = findCircular(value, newPath, seen);
        if (result) {
            return result;
        }
    }

    return null; // Retorna nulo se não encontrar nenhuma referência circular
};

function goBack() {
    if (window.history.length > 1) {
        window.history.back();
    } else {
        window.location.href = "/app";
    }
}

window.goBack = goBack;
